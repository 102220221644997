document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.mobileNav__list .menu-item-has-children')) {
		let subMenus = document.querySelectorAll(
			'.mobileNav__list .menu-item-has-children'
		);
		subMenus.forEach((subMenu) => {
			let link = subMenu.querySelector('a');
			let span = subMenu.querySelector('span');
			let ul = subMenu.querySelector('ul');
			link.addEventListener('click', (e) => {
				if (subMenu.querySelector('span')) {
					if(e.target instanceof HTMLImageElement ){
						e.preventDefault();
						ul.classList.toggle('sub-menu--open');
						span.classList.toggle('open');
					}
				}
			});
		});
	}
	const header = document.querySelector('.header');

	const hamburger = document.querySelector('.header .hamburger');
	const mobileNav = document.querySelector('.mobileNav');
	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('hamburger--opened');
		header.classList.toggle('header--scrolled');
		hamburger.setAttribute(
			'aria-expanded',
			hamburger.classList.contains('hamburger--opened')
		);
		mobileNav.classList.toggle('open');
	});
	window.addEventListener('scroll', () => {
		document.body.scrollTop > 40 || document.documentElement.scrollTop > 40
			? header.classList.add('header--scrolled')
			: header.classList.remove('header--scrolled');
	});

	if (document.querySelector('.hero__slider')) {
		const hero__slider = new Swiper('.hero__slider .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 5000,
			},
			pagination: {
				clickable: true,
				el: '.hero__pagination',
			},
		});
	}

	if (document.querySelector('.quality')) {
		const quality = new Swiper('.quality .swiper', {
			slidesPerView: 2,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			pagination: {
				el: '.quality .swiper-pagination',
			},
			breakpoints: {
				550: {
					slidesPerView: 3,
				},
				900: {
					slidesPerView: 4,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});
	}
	if (document.querySelector('.qualityBlock')) {
		const quality = new Swiper('.qualityBlock .swiper', {
			slidesPerView: 2,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			pagination: {
				el: '.qualityBlock .swiper-pagination',
			},
			breakpoints: {
				550: {
					slidesPerView: 3,
				},
				900: {
					slidesPerView: 4,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});
	}

	if (document.querySelector('.cooperation')) {
		var numOfSlides = 0;
		const hero__slider = new Swiper('.cooperation .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			effect: 'fade',
			autoplay: {
				delay: 5000,
			},
			on: {
			  beforeInit: function(){
			  numOfSlides = this.wrapperEl.querySelectorAll(".swiper-slide").length;
			  },
			  slideChange: function(){
				var currentSlide = this.realIndex + 1;
				var divs = document.querySelectorAll('.cooperation .swiper-navigation > span')
				divs[0].style.display = 'none';
				divs[1].style.display = 'none';
				divs[2].style.display = 'none';
				divs[3].style.display = 'none';
				if(this.realIndex == 0){
					divs[0].style.display = 'block';
					divs[1].style.display = 'block';
					divs[2].style.display = 'none';
					divs[3].style.display = 'none';
				}
				if(this.realIndex == 1){
					divs[0].style.display = 'none';
					divs[1].style.display = 'block';
					divs[2].style.display = 'none';
					divs[3].style.display = 'none';
				}
				if(this.realIndex == numOfSlides - 2){
					divs[0].style.display = 'none';
					divs[1].style.display = 'none';
					divs[2].style.display = 'none';
					divs[3].style.display = 'block';
				}
				if(this.realIndex ==  numOfSlides - 1){
					divs[0].style.display = 'none';
					divs[1].style.display = 'none';
					divs[2].style.display = 'block';
					divs[3].style.display = 'block';
				}
			  },
			},
			pagination: {
				el: '.cooperation .cooperation__navigation',
				clickable: true,
				renderBullet: function (index, className) {
					if (index + 1 < 10) {
						var number = '0' + (index + 1);
					} else {
						var number = index + 1;
					}
					return '<span data-index="'+index+'" class="' + className + '">' + number + '</span>';
				},
			},
			navigation: {
				prevEl: '.cooperation .swiper-prev',
				nextEl: '.cooperation .swiper-next',
			},
		});
	}
	if (document.querySelector('.heroSlider')) {
		const heroSlider = new Swiper('.heroSlider', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			effect: 'fade',
			autoplay: {
				delay: 5000,
			},
			pagination: {
				clickable: true,
				el: '.heroSlider__pagination',
			},
			navigation: {
				nextEl: '.heroSlider__next',
				prevEl: '.heroSlider__prev',
			},
		});
	}
	if (document.querySelector('.testimonies')) {
		const testimonies = new Swiper('.testimonies .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			effect: 'fade',
			autoHeight : 1,
			autoplay: {
				delay: 5000,
			},
			pagination: {
				clickable: true,
				el: '.testimonies .swiper-navigation',
			},
			navigation: {},
		});
		const nexts = document.querySelectorAll('.testimonies .swiper-next');
		nexts.forEach((next) => {
			next.addEventListener('click', () => {
				testimonies.slideNext();
			});
		});
		const prevs = document.querySelectorAll('.testimonies .swiper-prev');
		prevs.forEach((prev) => {
			prev.addEventListener('click', () => {
				testimonies.slidePrev();
			});
		});
	}
	if (document.querySelector('.cardsSlider')) {
		const accessories = new Swiper('.cardsSlider .swiper--accessories', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				prevEl: '.cardsSlider__slider--accessories .cardsSlider__prev',
				nextEl: '.cardsSlider__slider--accessories .cardsSlider__next',
			},
			breakpoints: {
				900: {
					slidesPerView: 2,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});
		const related = new Swiper('.cardsSlider .swiper--related', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				prevEl: '.cardsSlider__slider--related .cardsSlider__prev',
				nextEl: '.cardsSlider__slider--related .cardsSlider__next',
			},
			breakpoints: {
				900: {
					slidesPerView: 2,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});
	}
	if (document.querySelector('.usage')) {
		const buttons = document.querySelectorAll('.usage__button');
		buttons.forEach((button) => {
			button.addEventListener('click', () => {
				if (button.classList.contains('.usage__button--active')) {
					return;
				}
				var activeButton = document.querySelector('.usage__button--active');
				var activeCard = document.querySelector('.usage__card--active');
				activeButton.classList.remove('usage__button--active');
				activeCard.classList.remove('usage__card--active');
				button.classList.add('usage__button--active');
				var card = document.querySelector(
					'.usage__card[data-usage="' + button.getAttribute('data-usage') + '"]'
				);
				card.classList.add('usage__card--active');
			});
		});
	}

	if (document.querySelector('.gallery')) {
		const bigSlider = new Swiper('.gallery__bigSlider .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			effect: 'fade',
			navigation: {
				prevEl: '.gallery__thumbnails .swiper-prev',
				nextEl: '.gallery__thumbnails .swiper-next',
			},
		});
		const smallSlider = new Swiper('.gallery__thumbnails .swiper', {
			slidesPerView: 'auto',
			spaceBetween: 10,
			speed: 1000,
			centeredSlides: true,
			slideToClickedSlide: true,
			autoplay: {
				delay: 5000,
			},
			breakpoints: {
				1100: {
					direction: 'vertical',
					spaceBetween: 30,
				},
			},
		});
		bigSlider.controller.control = smallSlider;
		smallSlider.controller.control = bigSlider;
	}
	if (document.querySelector('.galleryBlock')) {
		const bigSlider = new Swiper('.galleryBlock__bigSlider .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			effect: 'fade',
			navigation: {
				prevEl: '.galleryBlock__thumbnails .swiper-prev',
				nextEl: '.galleryBlock__thumbnails .swiper-next',
			},
		});
		const smallSlider = new Swiper('.galleryBlock__thumbnails .swiper', {
			slidesPerView: 'auto',
			spaceBetween: 10,
			speed: 1000,
			centeredSlides: true,
			slideToClickedSlide: true,
			autoplay: {
				delay: 5000,
			},
			breakpoints: {
				1100: {
					direction: 'vertical',
					spaceBetween: 30,
				},
			},
		});
		bigSlider.controller.control = smallSlider;
		smallSlider.controller.control = bigSlider;
	}

	if (document.querySelector('.formSwitcher')) {
		const titles = document.querySelectorAll('.formSwitcher__title');
		titles.forEach((title) => {
			title.addEventListener('click', () => {
				if (title.classList.contains('formSwitcher__title--active')) {
					return;
				}

				let index = title.getAttribute('data-index');
				let activeTitle = document.querySelector('.formSwitcher__title--active');
				let activeContent = document.querySelector(
					'.formSwitcher__content--active'
				);
				activeTitle.classList.remove('formSwitcher__title--active');
				activeContent.classList.remove('formSwitcher__content--active');
				title.classList.add('formSwitcher__title--active');
				let content = document.querySelector(
					`.formSwitcher__content[data-index="${index}"]`
				);
				content.classList.add('formSwitcher__content--active');
			});
		});
	}

	if (document.querySelector('.blog-archive')) {
		const buttons = document.querySelectorAll('.blog-archive__buttons .button');
		const template = document.querySelector('#blog_card');
		const cards_container = document.querySelector('.blog-archive__cards');

		buttons.forEach((button) => {
			button.addEventListener('click', async () => {
				if (!button.getAttribute('data-active')) {
					return;
				}
				cards_container.classList.add('blog-archive--loading');
				var posts = await getBlogPosts(button.getAttribute('data-category-slug'));
				if(posts['error']){
					return
				}
				cards_container.innerHTML = '';
				posts.forEach((post) => {
					var card_clone = template.content.cloneNode(true);
					var a = card_clone.querySelector('a');
					a.setAttribute('href', post['link']['url']);
					var img = card_clone.querySelector('img')
					img.setAttribute('src',post['image']['url'])
					img.setAttribute('alt',post['image']['alt'])
					var title = card_clone.querySelector('span')
					title.innerHTML = post['title']
					cards_container.appendChild(card_clone)
				});
				cards_container.classList.remove('blog-archive--loading');
				var active_button = document.querySelector('.blog-archive__buttons [data-active="1"]')
				active_button.setAttribute('data-active',0)
				active_button.classList.add('button--inverted')
				button.setAttribute('data-active',1)
				button.classList.remove('button--inverted')
			});
		});
	}

	if(document.querySelectorAll('.effectBlock')){
		const blocks  = document.querySelectorAll('.effectBlock')

		blocks.forEach(block => {
			block.querySelector('input').addEventListener('input', (e)=>{
				block.style.setProperty('--position',`${e.target.value}%`)
			})
		});

	}
	if( document.querySelector('.caseStudyGallery')){
		const caseStudyGallery = new Swiper('.caseStudyGallery .swiper', {
			slidesPerView: 1,
			spaceBetween: 10,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				prevEl: '.caseStudyGallery .caseStudyGallery__prev',
				nextEl: '.caseStudyGallery .caseStudyGallery__next',
			},
			breakpoints: {
				1100: {
					slidesPerView: 2,
				},
			},
			pagination: {
				clickable: true,
				el: '.caseStudyGallery .swiper-navigation',
			},
		});
	}
});

const getBlogPosts = async (slug) => {
	return $.ajax({
		type: 'post',
		dataType: 'json',
		url: luxon_globals.ajax_url,
		data: {
			category_slug: slug,
			action: 'change_blog_posts',
			_ajax_nonce: luxon_globals.nonce,
		},
	});
};
