document.addEventListener('DOMContentLoaded', () => {
	const scrollTop = document.querySelector('.scrollTop');

	scrollTop.addEventListener('click', () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

    window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollTop.style.display = 'flex';
		} else {
            scrollTop.style.display = 'none';
		}
	}
});
