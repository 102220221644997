document.addEventListener('DOMContentLoaded', () => {
	const archive = document.querySelector('.case-study-archive');
	const container = document.querySelector('.case-study-archive__cards');
	if (!archive) {
		return;
	}

	const buttons = document.querySelectorAll(
		'.case-study-archive__buttons .button'
	);

	buttons.forEach((button) => {
		button.addEventListener('click', async () => {
			var active = button.getAttribute('data-active');
			if (parseInt(active)) return;
			var taxonomy = button.getAttribute('data-taxonomy');
			container.classList.add('loading');
			var response = await getCaseStudyPosts(taxonomy, 1);
			if (response['posts']) {
				await changePosts(response['posts'], 0);
				await changeLoadButton(response['paged'], response['max_num_pages']);
			} else {
				alert('error');
				return;
			}

			buttons.forEach((b) => {
				if (b.getAttribute('data-active') == 1) {
					b.setAttribute('data-active', 0);
					b.classList.add('button--inverted');
				}
			});
			button.setAttribute('data-active', 1);
			button.classList.remove('button--inverted');

			container.classList.remove('loading');
		});
	});
	const load_more_button = document.querySelector(
		'.case-study-archive__more .button'
	);
	load_more_button.addEventListener('click', async () => {
		var paged = load_more_button.getAttribute('data-paged');
		var taxonomy = document
			.querySelector('.case-study-archive__buttons .button[data-active="1"]')
			.getAttribute('data-taxonomy');

		container.classList.add('loading');
		if (!taxonomy) return;
		var response = await getCaseStudyPosts(taxonomy, paged);

		if (response['posts']) {
			await changePosts(response['posts'], 1);
			await changeLoadButton(response['paged'], response['max_num_pages']);
		} else {
			alert('error');
			return;
		}
		container.classList.remove('loading');
	});
});

const changeLoadButton = async (paged, max_num_pages) => {
	const load_more_button = document.querySelector(
		'.case-study-archive__more .button'
	);
	load_more_button.style.display = paged < max_num_pages ? 'flex' : 'none';
	load_more_button.setAttribute('data-paged', parseInt(paged) + 1);
};

const changePosts = async (posts, add) => {
	const container = document.querySelector('.case-study-archive__cards');
	const template = document.querySelector('#case-study-archive__template');

	if (!posts) return;

	if (!add) {
		container.innerHTML = '';
	}

	var counter = container.querySelectorAll('.case-study-card').length;

	posts.forEach((post) => {
		var clone = template.content.cloneNode(true);
		var image = clone.querySelector('.case-study-card__image');
		var sygnet = clone.querySelector('.case-study-card__sygnet');
		if (counter % 2) {
			clone
				.querySelector('.case-study-card')
				.classList.add('case-study-card--inverted');
		}

		image.setAttribute('alt', post['image']['alt']);
		image.setAttribute('src', post['image']['url']);

		sygnet.setAttribute('alt', post['sygnet']['alt']);
		sygnet.setAttribute('src', post['sygnet']['url']);

		var a = clone.querySelector('.button');
		a.setAttribute('href', post['link']['url']);
		a.setAttribute('target', post['link']['target']);
		a.setAttribute('title', post['link']['title']);

		var list = '';
		post['list'].forEach((item) => {
			list += `<div class="case-study-card__item fade-up"><span class="heading-large weight-semiBold">${item['title']} </span><p class="text-big weight-light">${item['text']} </p></div>`;
		});
		var content = clone.querySelector('.case-study-card__content');
		content.innerHTML = list + content.innerHTML;
		container.appendChild(clone);
		++counter;
	});
};

const getCaseStudyPosts = async (taxonomy_id, paged) => {
	return $.ajax({
		type: 'post',
		dataType: 'json',
		url: luxon_globals.ajax_url,
		data: {
			taxonomy_id: taxonomy_id,
			paged: paged,
			action: 'get_case_study_posts',
			_ajax_nonce: luxon_globals.nonce,
		},
	});
};
