document.addEventListener('DOMContentLoaded', () => {
	const search = document.querySelector('.searchForm');
	const searchButton = document.querySelector('.header__search');

	const handleBackgroundClick = (e) => {
		if (
			e.target.classList.contains('container') ||
			e.target.classList.contains('searchForm')
		) {
			search.classList.remove('searchForm--visible');
			searchButton.classList.remove('header__search--open');
		}
	};
	const handleSearchButtonClick = () => {
		search.classList.toggle('searchForm--visible');
		searchButton.classList.toggle('header__search--open');
	};
	const handleEscKeyPress = (event) => {
		if (event.key === 'Escape') {
			if (search.classList.contains('searchForm--visible')) {
				search.classList.remove('searchForm--visible');
				searchButton.classList.remove('header__search--open');
			}
		}
	};
	search.addEventListener('click', handleBackgroundClick);
	searchButton.addEventListener('click', handleSearchButtonClick);

	document.addEventListener('keydown', handleEscKeyPress);
});
