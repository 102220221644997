document.addEventListener('DOMContentLoaded', () => {
	const buttons = document.querySelectorAll(
		'.product-category__buttons .product-category__button'
	);

	const cards = document.querySelectorAll('.categoryCard');

	buttons.forEach((button) => {
		button.addEventListener('click', () => {
			if (button.classList.contains('product-category__button--active')) {
				return;
			}

			var slug = button.getAttribute('data-slug');

			if (slug == 'all') {
				cards.forEach((card) => {
					card.style.display = 'block';
				});
			} else {
				cards.forEach((card) => {
					if (card.getAttribute('data-cats').split('|').includes(slug)) {
						card.style.display = 'block';
					} else {
						card.style.display = 'none';
					}
				});
			}
			var activeButton = document.querySelector(
				'.product-category__button--active'
			);
			activeButton.classList.remove('product-category__button--active');
			button.classList.add('product-category__button--active');
		});
	});
});
